<template>
    <div>
        <app-layout>
	        <template v-slot:header>
                <Header :title="$t('batch_gno_calculation')"></Header>
	        </template>
	        <template v-slot:header-mobile>
                <HeaderMobile :title="$t('batch_gno_calculation')"></HeaderMobile>
	        </template>
            <ValidationObserver ref="formValidate">
                <b-row class="mt-5">
                    <b-col cols="6" xs="12">
                        <b-row>
                            <b-col cols="12">
                                <ValidationProvider name="faculty_code" rules="" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('faculty')">
                                        <faculty-selectbox v-model="formData.faculty_code"
                                                           :validateError="errors[0]">
                                        </faculty-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col cols="12">
                                <ValidationProvider name="department_code" rules="" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('department')">
                                        <department-selectbox v-model="formData.department_code"
                                                              :faculty_code="formData.faculty_code"
                                                              :validateError="errors[0]">
                                        </department-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col cols="12">
                                <ValidationProvider name="program" rules="" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('program')">
                                        <program-selectbox v-model="formData.program_code"
                                                           :department_code="formData.department_code"
                                                           :validateError="errors[0]">
                                        </program-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="6" xs="12">
                        <b-row>
                            <b-col cols="12">
                                <ValidationProvider name="student_numbers" rules="" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('students')">
                                        <lined-textarea v-model="formData.student_numbers"
                                                        :nowrap="false"
                                                        :disabled="false"
                                                        :styles="{ height: '15em', resize: 'both' }"
                                                        :validateError="errors[0]">
                                        </lined-textarea>
                                        <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
                <div class="d-flex mt-2">
                    <b-button variant="primary" @click="create">{{ $t('calculate').toUpper() }}</b-button>
                </div>
            </ValidationObserver>
        </app-layout>
    </div>
</template>

<script>
    import AppLayout from "@/layouts/AppLayout"
    import Header from "@/layouts/AppLayout/Header"
    import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"

    import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
    import DepartmentSelectbox from "@/components/interactive-fields/DepartmentSelectbox";
    import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox";
    import LinedTextarea from "@/components/elements/LinedTextarea";

    import {ValidationObserver, ValidationProvider} from "vee-validate";
    import StudentService from "@/services/StudentService";

    export default {
        components: {
            AppLayout,
            Header,
            HeaderMobile,

            FacultySelectbox,
            DepartmentSelectbox,
            ProgramSelectbox,
            LinedTextarea,

            ValidationProvider,
            ValidationObserver,
        },
        metaInfo() {
            return {
                title: this.$t('students')
            }
        },
        computed: {
            convertStudentNumbers() {
                if(this.formData.student_numbers){
                    return this.formData.student_numbers.split(/\n/);
                }
                return [];
            }
        },
        data() {
            return {
                formData: {
                    faculty_code: null,
                    department_code: null,
                    program_code: null,
                    student_numbers: '',
                }
            }
        },
        methods: {
            async create() {
                const isValid = await this.$refs.formValidate.validate();
                if (isValid) {
                    let formData = {
                        'faculty_code' : this.formData.faculty_code,
                        'department_code' : this.formData.department_code,
                        'program_code' : this.formData.program_code,
                        'student_numbers' : this.convertStudentNumbers
                    }

                    StudentService.calculateGpa(formData)
                                        .then(response => {
                                            this.$toast.success(this.$t('api.' + response.data.message));
                                            this.$router.push('/job/logs/detail/'+response.data.data.log_id);
                                        })
                                        .catch(e => {
                                            if (e.status == 422) {
                                                if (e.data.errors.faculty_code) {
                                                    this.$refs.formValidate.errors.faculty_code.push(e.data.errors.faculty_code[0]);
                                                }
                                                if (e.data.errors.department_code) {
                                                    this.$refs.formValidate.errors.department_code.push(e.data.errors.department_code[0]);
                                                }
                                                if (e.data.errors.program_code) {
                                                    this.$refs.formValidate.errors.program_code.push(e.data.errors.program_code[0]);
                                                }
                                                if (e.data.errors.student_numbers) {
                                                    this.$refs.formValidate.errors.student_numbers.push(e.data.errors.student_numbers[0]);
                                                }
                                            }
                                            else if (e.status == 406) {
                                                this.$toast.error(this.$t('api.' + e.data.message));
                                            }
                                        })
                }
            },
        }
    };
</script>

